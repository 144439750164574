@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    scroll-behavior: smooth;
    background: #fff;
    /*f7f7f7*/
    font-family: 'Poppins', sans-serif;
}

button {
    outline: none;
}

.shadow-pulse-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    background-color: gray;
}

.shadow-pulse-circle.synced {
    background: rgb(120, 192, 0);
    animation: shadow-pulse-green 1s infinite;
}

.shadow-pulse-circle.halted,
.shadow-pulse-circle.inactive {
    background: rgb(220, 53, 69);
    animation: shadow-pulse-red 1s infinite;
}

.copy-to-clipboard-button {
    @apply w-full rounded-md;
}

pre, code {
    /*max-width: 76vw !important;*/
    color: #000 !important;
    background: rgba(201, 195, 195, 0.15) !important;
    text-shadow: none !important;
}

strong {
    padding-left: 5px;
}

.customPre {
    color: #000;
    background: rgba(201, 195, 195, 0.15);
    text-shadow: none !important;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
    padding: 1em;
    margin: 0.5em 0px;
    overflow: auto;
    border-radius: 0.3em;
}


.code-toolbar .toolbar {
    top: 0.6em !important;
    right: 0.6rem !important;
}

.code-toolbar button {
    @apply shadow-md;
    background: #fff !important;
    padding: 5px 15px !important;
    border: 1px solid #fff !important;
}

.code-toolbar button:hover {
    border: 1px solid #959595 !important;
}

.code-toolbar span {
    font-size: 1rem !important;
    color: #454545 !important;
}

.utilityButtonList button:first-child {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.utilityButtonList button:last-child {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.twitterContainer iframe {
    @apply rounded-xl;
}

-container {
    height: 600px;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

boxgl-popup-content {
    min-width: 380px;
}

boxgl-popup-content h4 {
    font-size: 14px;
}

boxgl-popup-close-button {
    font-size: 40px;
    top: 10px;
    right: 10px;
    outline: none;
}

.progressWrapper div{
    background: rgba(0,0,0, 0.04) !important;
}

.progressWrapper div div {
    background: linear-gradient(90deg,#6b81e0 0,#adb8ee) !important;
}

.progressWrapper span {
    font-size: 12px !important;
}

#chainExtra {
    transition: 0.6s;
}

.menuActive {
    transition: 0.6s;
    display: block;
    position: fixed;
    right: 0;
    left: initial;
    top: 4rem;
}

@media screen and (max-width: 400px) {
    .pagination ul {
        padding: 0;
    }
}

.pagination ul {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    place-items: center;
    user-select: none !important;
}

.pagination ul li.selected {
    color: red;
}

.pagination ul li.disabled {
    color: #999;
}

.pagination ul li.disabled:hover {
    color: #999 !important;
}

.pagination ul li:hover {
    color: #333;
}

#header {
    transition: 0.3s ease-in-out;
}

.scroll-header {
    background: rgba(0,0,0,0.92);
    @apply shadow-lg;
}

.scroll-header #logoContainer {
}

.scroll-header #socialIcon {
    color: #999;
    transition: 0.3s;
}

.scroll-header #socialIcon:hover {
    color: #3956de;
}

.primaryButton {
    background: linear-gradient(90deg,#3956de 0,#657be5);
    border: none;
    color: #fff;
    box-shadow: 0 4px 20px rgba(57,86,222,.15);
}

.primaryButton:hover {
    box-shadow: 0 3px 10px rgba(57,86,222,.5);
    transform: translateY(-2px);
    transition: all .5s;
}

.aboutList svg {
    transition: 0.3s;
}

.aboutList:hover svg {
    color: #fff;
}

.tileBg {
    background: url('/public/images/tile-bg.png'), #9288cf; /*7a6fbe*/
    background-repeat: no-repeat;
    background-position-y: bottom;
    opacity: 0.95;
    background-size: 100%;
    width: 100%;
    height: 100%;
}

.chainCard,
.chainCard:hover,
.buttonsContainer,
.extraContainer {
    transition: 0.3s ease-in-out;
}

.chainCard:hover .extraContainer {
    display: none;
}

.chainCard:hover .buttonsContainer {
    transition: 0.3s;
    display: flex !important;
}

.map-container {
    height: 500px !important;
}